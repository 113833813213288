import { useEffect, RefObject } from 'react';
import Hls from 'hls.js';

interface HlsProps {
  videoElement: RefObject<HTMLVideoElement>;
  videoSrc: string;
  autoPlay?: boolean;
  hasMasterPlaylist?: boolean;
}

const useHLS = ({
  videoElement,
  videoSrc,
  autoPlay,
  hasMasterPlaylist,
}: HlsProps) => {
  useEffect(() => {
    if (videoElement.current === null || !hasMasterPlaylist) {
      return;
    }
    if (Hls.isSupported()) {
      const hls = new Hls({ startLevel: -1 }); // auto video quality

      hls.loadSource(videoSrc);
      hls.attachMedia(videoElement.current);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoPlay) {
          videoElement.current?.play();
        }
      });

      // eslint-disable-next-line
      return () => {
        if (hls) {
          hls.destroy();
        }
      };
    }
    if (videoElement.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.current.src = videoSrc;
    }
  }, [videoSrc, autoPlay, videoElement]);
};

export default useHLS;
